import '../styles/globals.scss';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Toaster, ToasterProvider } from '@latitude/toast';
import { TooltipProvider } from '@latitude/tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PORTO_RECAPTCHA_V3_SITE_KEY } from 'constants/app';
import { AppContextProvider as AppContextProviderNew } from 'context/AppContextNEW/AppContextNEW';
import { AppContextProvider } from 'legacy/context/AppContext';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import DrawerProvider from '@anchorage/backoffice/components/DrawerProvider/DrawerProvider';
import { NonceProvider } from '@anchorage/common/dist/components/SelectV2';
import '@anchorage/common/dist/components/index.css';
import useInfoDrawer from 'hooks/useInfoDrawer';
import { SESSION_TYPE_HEADER, getSessionInfo } from 'utils/api';
import useIsPortoApp from 'utils/useIsPortoApp';
import usePortoTheme from 'utils/usePortoTheme';
import 'normalize.css';
import css from './styles.module.css';
import 'legacy/fonts/fonts.css';
import { CSP } from './_document';
const queryClient = new QueryClient();
const connectToDevTools = process.env['NODE_ENV'] !== 'production';
const initApolloClient = () => {
  const {
    sessionFormType,
    id
  } = getSessionInfo();
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        QuestionnaireNavigationItem: {
          keyFields: ['pageID']
        }
      }
    }),
    connectToDevTools,
    uri: `/graphql/${id}`,
    // Pass along cookies
    credentials: 'same-origin',
    // This only works because there is no way to navigate between routes that
    // would produce different results for "getSessionTypeValue".
    headers: {
      [SESSION_TYPE_HEADER]: sessionFormType
    }
  });
};
function MyApp({
  Component,
  pageProps
}: AppProps) {
  const [skipSSG, setSkipSSG] = useState(true);
  const {
    basePath,
    asPath,
    replace
  } = useRouter();
  const sessionInfo = getSessionInfo();
  const isPorto = useIsPortoApp();
  const client = useMemo(() => initApolloClient(), []);
  const {
    component: InfoDrawer
  } = useInfoDrawer();
  useEffect(() => {
    setSkipSSG(false);
  }, [skipSSG]);
  usePortoTheme({
    active: isPorto
  });
  const faviconPath = useMemo(() => {
    return `${basePath}/${isPorto ? 'portoFavicon.ico' : 'favicon.ico'}`;
  }, [isPorto, basePath]);
  useEffect(() => {
    // redirect to home if it's not home
    if (isPorto && asPath !== '/') {
      replace('/');
    }
  }, [isPorto, asPath, replace]);
  return (
    //@ts-ignore react in common is older than here. This results in an incompatibility of types
    <NonceProvider nonce={CSP.nonce} cacheKey="anchor-key" data-sentry-element="NonceProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <link id="favicon" rel="shortcut icon" href={faviconPath} />
      </Head>
      <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.tsx">
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <GoogleReCaptchaProvider reCaptchaKey={isPorto ? PORTO_RECAPTCHA_V3_SITE_KEY : ''} useEnterprise={true} data-sentry-element="GoogleReCaptchaProvider" data-sentry-source-file="_app.tsx">
            <TooltipProvider delayDuration={300} skipDelayDuration={500} data-sentry-element="TooltipProvider" data-sentry-source-file="_app.tsx">
              <ToasterProvider data-sentry-element="ToasterProvider" data-sentry-source-file="_app.tsx">
                <AppContextProvider sessionInfo={sessionInfo} data-sentry-element="AppContextProvider" data-sentry-source-file="_app.tsx">
                  <AppContextProviderNew sessionInfo={sessionInfo} data-sentry-element="AppContextProviderNew" data-sentry-source-file="_app.tsx">
                    {!skipSSG && <DrawerProvider>
                        <div className={css.app}>
                          <Component {...pageProps} />
                          <div id="modal-root" />
                          <div id="drawer-root" />
                          <Toaster />
                        </div>
                        {InfoDrawer}
                      </DrawerProvider>}
                  </AppContextProviderNew>
                </AppContextProvider>
              </ToasterProvider>
            </TooltipProvider>
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </NonceProvider>
  );
}
export default MyApp;
import { Text } from '@latitude/text';
import css from './styles.module.css';
export const AML_DRAWER_CONTENT = {
  title: 'Anti-money laundering program (AML)',
  content: <>
      <Text>
        This program is designed to ensure ongoing compliance with the Bank
        Secrecy Act and its regulations. It includes:
      </Text>
      <ul className={css.list}>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Written policies and procedures: `}</span>
            Implemented to maintain compliance with relevant AML laws and
            regulations.
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Suspicious activity reporting: `}</span>
            Mechanisms to detect and report suspicious activity.
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Sanctions screening: `}</span>
            Regular checks of customers and transactions against OFAC sanctions
            lists and Politically Exposed Persons (PEPs).
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Independent testing: `}</span>

            {"Periodic reviews to ensure the program's effectiveness."}
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Designated personnel: `}</span>
            Individuals appointed to manage and monitor the program.
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`AML and sanctions training: `}</span>
            Ongoing education for relevant staff.
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Customer due diligence: `}</span>
            Procedures to understand and monitor customer relationships and
            transactions, including updates on beneficial ownership.
          </Text>
        </li>
        <li>
          <Text>
            <span className={css.drawerTextWeight}>{`Record retention: `}</span>
            Processes to retain necessary documentation.
          </Text>
        </li>
      </ul>
    </>
};
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
interface SessionInfo {
  id: string;
  access: string;
  sessionFormType: string;
}
interface AppContextProps {
  currentPageId: string | undefined;
  setCurrentPageId: (pageIndex: string, scroll?: boolean) => void;
  completedNavigationItemsIds: string[];
  setCompletedNavigationItemsIds: (ids: string[]) => void;
  sessionInfo: SessionInfo;
}
export const AppContext = createContext<AppContextProps>({
  currentPageId: undefined,
  setCurrentPageId: () => '',
  completedNavigationItemsIds: [],
  setCompletedNavigationItemsIds: () => '',
  sessionInfo: {
    id: '',
    access: '',
    sessionFormType: ''
  }
});
export const AppContextProvider = ({
  children,
  sessionInfo
}: {
  children: ReactNode;
  sessionInfo: SessionInfo;
}) => {
  const router = useRouter();
  const [currentPageId, setCurrentPageId] = useState<string | undefined>();
  const [completedNavigationItemsIds, setCompletedNavigationItemsIds] = useState<string[]>([]);
  const setCurrentPageIdAndRoute = useCallback((id: string, scroll: boolean = true) => {
    if (id && id !== currentPageId) {
      setCurrentPageId(id);
      router.push({
        query: {
          ...router.query,
          page: id
        }
      }, undefined, {
        scroll
      });
    }
  }, [router, currentPageId]);
  return <AppContext.Provider value={{
    currentPageId,
    setCurrentPageId: setCurrentPageIdAndRoute,
    completedNavigationItemsIds,
    setCompletedNavigationItemsIds,
    sessionInfo
  }} data-sentry-element="unknown" data-sentry-component="AppContextProvider" data-sentry-source-file="AppContextNEW.tsx">
      {children}
    </AppContext.Provider>;
};
export const useAppContext = (): AppContextProps => useContext(AppContext);
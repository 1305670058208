import { useRouter } from 'next/router';
import * as React from 'react';
import { DrawerProps } from '@anchorage/common/dist/components/Drawer/types';
import { Drawer } from './components/Drawer';
type DrawerOptionsType = Partial<DrawerProps>;
type ContextProps = {
  close: () => void;
  isOpen: boolean;
  open: (value: React.ReactNode, options?: DrawerOptionsType) => void;
  update: (value: React.ReactNode) => void;
};
const contextInitialValue = {
  close: () => {},
  isOpen: false,
  open: () => {},
  update: () => {}
};
export const DrawerContext = React.createContext<ContextProps>(contextInitialValue);
type Props = {
  children: React.ReactNode;
};
function DrawerProvider({
  children
}: Props) {
  const {
    pathname
  } = useRouter();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [drawerContent, setDrawerContent] = React.useState<React.ReactNode>(null);
  const [drawerOptions, setDrawerOptions] = React.useState<DrawerOptionsType>({});
  const open = React.useCallback((content: React.ReactNode, options: DrawerOptionsType = {}) => {
    setDrawerContent(content);
    setDrawerOptions(options);
    setIsDrawerOpen(true);
  }, []);
  const update = React.useCallback((content: React.ReactNode) => {
    setDrawerContent(content);
  }, []);
  const close = React.useCallback(() => {
    setDrawerContent(null);
    setDrawerOptions({});
    setIsDrawerOpen(false);
  }, []);
  const providerValue = React.useMemo(() => ({
    close,
    isOpen: isDrawerOpen,
    open,
    update
  }), [close, isDrawerOpen, open, update]);
  React.useEffect(() => {
    if (isDrawerOpen) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, close]);
  return <DrawerContext.Provider value={providerValue} data-sentry-element="unknown" data-sentry-component="DrawerProvider" data-sentry-source-file="DrawerProvider.tsx">
      {children}
      <Drawer data-testid="drawer-provider-drawer" visible={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} {...drawerOptions} data-sentry-element="Drawer" data-sentry-source-file="DrawerProvider.tsx">
        {drawerContent}
      </Drawer>
    </DrawerContext.Provider>;
}
export default DrawerProvider;
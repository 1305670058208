import { SPLIT_OPTIONS } from 'constants/split';
import * as React from 'react';

// Components
import { FeatureFlagProvider } from '@anchorage/feature-flags';
function FeatureFlagsProvider({
  children
}: {
  children: JSX.Element;
}) {
  return <FeatureFlagProvider baseAttributes={{}} defaults={SPLIT_OPTIONS.defaults} options={{
    ...SPLIT_OPTIONS.options,
    trafficType: 'user',
    trafficKey: 'onboarding',
    project: 'onboarding-questionnaires'
  }} data-sentry-element="FeatureFlagProvider" data-sentry-component="FeatureFlagsProvider" data-sentry-source-file="index.tsx">
      {children}
    </FeatureFlagProvider>;
}
export default FeatureFlagsProvider;
import { Button } from '@latitude/button';
import { Text } from '@latitude/text';
import { Drawer } from '@anchorage/common/dist/components';
import { CloseIcon } from '@anchorage/common/dist/components/Icons';
import { LANDING_PAGE_DRAWERS, LANDING_PAGE_DRAWERS_CONTENT } from 'components/LandingPage/helpers';
import css from './styles.module.css';
import { AML_DRAWER_CONTENT } from './helpers';
export const AML_DRAWER = 'AML_DRAWER';
export type DrawerID = LANDING_PAGE_DRAWERS | 'AML_DRAWER';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  drawerID: DrawerID;
};
const getDrawerContent = (id: DrawerID) => {
  switch (id) {
    case AML_DRAWER:
      return {
        title: AML_DRAWER_CONTENT.title,
        content: AML_DRAWER_CONTENT.content
      };
    default:
      return {
        title: LANDING_PAGE_DRAWERS_CONTENT?.[id]?.title,
        content: LANDING_PAGE_DRAWERS_CONTENT?.[id]?.content
      };
  }
};
const InfoDrawer = ({
  drawerID,
  isOpen,
  onClose
}: Props) => {
  const {
    title,
    content
  } = drawerID ? getDrawerContent(drawerID) : {
    title: '',
    content: null
  };
  return <Drawer width={640} onClose={onClose} open={isOpen} noTitle drawerStyles={{
    body: {
      padding: 0
    }
  }} data-sentry-element="Drawer" data-sentry-component="InfoDrawer" data-sentry-source-file="InfoDrawer.tsx">
      <div className={css.drawerContent}>
        <>
          <div className={css.drawerMainTitleContainer}>
            <Text variant="headingSmall" data-sentry-element="Text" data-sentry-source-file="InfoDrawer.tsx">{title}</Text>
          </div>
          <div className={css.drawerContentContainer}>{content}</div>
        </>
        <div className={css.buttonDrawerContainer}>
          <Button onClick={onClose} data-sentry-element="Button" data-sentry-source-file="InfoDrawer.tsx">Close</Button>
        </div>
        <div className={css.closeButtonContainer}>
          <Button variant="strong" type="ghost" leadingElement={<CloseIcon />} onClick={onClose} data-sentry-element="Button" data-sentry-source-file="InfoDrawer.tsx" />
        </div>
      </div>
    </Drawer>;
};
export default InfoDrawer;